<script>
// For more information on implementing html2Pdf.js:
// https://ekoopmans.github.io/html2pdf.js
// https://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html

// import proximaNovaBase64 from "@v3c/assets/proximaNovaBase64.js";
import robotoMonoBase64 from "@v3c/assets/robotoMonoBase64.js";
// Monotype-font forced otherwise numbers won't line up correctly in tables for example.

import html2pdf from 'html2pdf.js'

export default {
    name: 'CrvHtmlToPdf',

    props: {
        name: {
            type: String,
            required: true,
            note: 'required PDF Document name',
        },

        format: {
            type: String,
            default: 'a4',
            note: 'default paper-format, default "a4", could be "letter" for international paper',
        },

        orientation: {
            type: String,
            default: 'portrait',
            note: 'default paper-orientation, default "portrait", could be "landscape"',
        },

        pageNumberLabel: {
            type: String,
            default: 'Pagina',
            note: 'Word shown in front of the page number, can be set for different locales"',
        },

        qualityScale: {
            type: Number,
            default: 2,
            note: `The scale of quality of the rendering, default 2, can be set higher for better quality.
            This does however increase the file-size and time to generate the PDF. Default is set to 2.`,
        },
    },

    methods: {
        generatePdf() {
            const pageNumberLabel = this.pageNumberLabel
            const pdfOptions = { // https://ekoopmans.github.io/html2pdf.js/#options
                pagebreak: {
                    mode: ['css', 'legacy'],
                    after: '.pagebreak'
                },
                margin: [5, 3],
                // Minimum top & bottom print-margins by Epson: 4.2 mm
                // Minimum left & right margins by Epson: 3.0 mm
                // Source: http://support2.epson.net/manuals/english/sidm/lq_350/useg/html/apspe_2.htm
                image: { type: 'jpeg', quality: 0.98 },
                enableLinks: true,
                html2canvas: { scale: this.qualityScale, useCORS: true }, 
                // The scale: 2 makes the rendering more precise. useCORS: true can help if you're loading external styles.
                // increasing the scale will increase the file-size, time to generate but also the quality of the rendering.
                jsPDF: {
                    unit: 'mm',
                    format: this.format,
                    orientation: this.orientation
                },
            }
            html2pdf()
                .set(pdfOptions)
                .from(this.$refs.pdfPrint)
                .toPdf().get('pdf').then(function (pdf) {
                    const totalPages = pdf.internal.getNumberOfPages()
                    pdf.addFileToVFS("RobotoMono-Regular.ttf", robotoMonoBase64);
                    pdf.addFont("RobotoMono-Regular.ttf", "RobotoMono", "normal");
                    pdf.setFont("RobotoMono");
                    pdf.setFontSize(8)
                    // Set font: https://stackoverflow.com/questions/58449153/jspdf-error-font-does-not-exist-in-vfs-vuejs

                    pdf.setTextColor(38, 35, 32)
                    for (let page = 1; page <= totalPages; page++) {
                        pdf.setPage(page)
                        // pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth()/2.25, // centered
                        pdf.text(`${pageNumberLabel} ${page} / ${totalPages}`, pdf.internal.pageSize.getWidth()/1.15, // bottom right
                            pdf.internal.pageSize.getHeight()-9.5
                        )
                    }
                })
                .save(this.name)
        }
    }
}
</script>

<template>
  <div
    ref="pdfPrint"
    class="pdf-print"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>

.pdf-print  {
    font-family: 'RobotoMono', sans-serif !important;

    :deep(table) {
        font-family: 'RobotoMono', sans-serif !important;
    }
}
</style>
