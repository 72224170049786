<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 162">
    <g fill="none" fill-rule="evenodd" transform="translate(0 78.469)">
      <ellipse cx="108" cy="62.859" fill="#F7F6F6" rx="108" ry="21.094" />
      <g transform="translate(23.203)">
        <path
          fill="#928782"
          d="M85.21875,0 C132.283766,0 170.4375,11.6161121 170.4375,25.9453125 C170.4375,26.0157895 170.436577,26.0862008 170.434735,26.1565454 L170.4375,43.03125 L170.412636,43.0310957 C170.429181,43.241474 170.4375,43.4524724 170.4375,43.6640625 C170.4375,57.9932629 132.283766,69.609375 85.21875,69.609375 C38.153734,69.609375 0,57.9932629 0,43.6640625 C0,43.4524724 0.00831924024,43.241474 0.0248635678,43.0310957 L0,43.03125 L0,26.15625 L0.005,26.156 L0,25.9453125 C0,11.7594041 37.3944747,0.23259355 83.8095021,0.0034759306 Z M85.21875,1.265625 C39.0857145,1.265625 1.6875,12.4095374 1.6875,26.15625 C1.6875,39.9029626 39.0857145,51.046875 85.21875,51.046875 C131.351786,51.046875 168.75,39.9029626 168.75,26.15625 C168.75,12.4095374 131.351786,1.265625 85.21875,1.265625 Z"
          transform="matrix(1 0 0 -1 0 69.61)"
        />
        <path
          fill="#BCB4B1"
          d="M85.21875,0 C132.283766,0 170.4375,11.6161121 170.4375,25.9453125 C170.4375,26.0157895 170.436577,26.0862008 170.434735,26.1565454 L170.4375,43.03125 L170.412636,43.0310957 C170.429181,43.241474 170.4375,43.4524724 170.4375,43.6640625 C170.4375,57.9932629 132.283766,69.609375 85.21875,69.609375 C38.153734,69.609375 0,57.9932629 0,43.6640625 C0,43.4524724 0.00831924024,43.241474 0.0248635678,43.0310957 L0,43.03125 L0,26.15625 L0.005,26.156 L0,25.9453125 C0,11.7594041 37.3944747,0.23259355 83.8095021,0.0034759306 Z M85.21875,1.265625 C39.0857145,1.265625 1.6875,12.4095374 1.6875,26.15625 C1.6875,39.9029626 39.0857145,51.046875 85.21875,51.046875 C131.351786,51.046875 168.75,39.9029626 168.75,26.15625 C168.75,12.4095374 131.351786,1.265625 85.21875,1.265625 Z"
        />
        <g transform="rotate(-94 86.407 -10.585)">
          <rect width="27.844" height="27.844" x="1" fill="#FEDB55" rx="1.688" />
          <text
            fill="#262322"
            font-family="OpenSans-SemiBold, Open Sans"
            font-size="23.203"
            font-weight="500"
            transform="rotate(90 14.844 14.172)"
          >
            <tspan x="7.844" y="25.172">4</tspan>
          </text>
        </g>
        <g transform="rotate(-90 73.273 1.977)">
          <rect width="27.844" height="27.844" x="1" fill="#FEDB55" rx="1.688" />
          <text
            fill="#262322"
            font-family="OpenSans-SemiBold, Open Sans"
            font-size="23.203"
            font-weight="500"
            transform="rotate(90 14.844 14.172)"
          >
            <tspan x="7.844" y="25.172">0</tspan>
          </text>
        </g>
        <g transform="rotate(-87 58.949 15.38)">
          <rect width="27.844" height="27.844" x="1" fill="#FEDB55" rx="1.688" />
          <text
            fill="#262322"
            font-family="OpenSans-SemiBold, Open Sans"
            font-size="23.203"
            font-weight="500"
            transform="rotate(90 14.844 14.172)"
          >
            <tspan x="7.844" y="25.172">4</tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
</template>
