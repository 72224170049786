
import { VBtn } from 'vuetify/components/VBtn'

const aliasesAndDefaults = {

    aliases: { 
        CrvBtnPrimary: VBtn as unknown,
        CrvBtnSubmit: VBtn as unknown,
    },
    defaults: {

        global: {
            ripple: false,
            // class: 'crv-vuetify-styling', // Global-set classes does not work.
          },

        CrvBtnPrimary: { // example alias for VBtn
            class: ['v-btn--primary', 'text-none'],
        },

        CrvBtnSubmit: { // example alias for Crv consistent Submit-VBtn
            color: "submit",
            type: "submit",
            variant: "flat"
        },

        //class: 'crv-vuetify-styling': Wrapper-class for most used vuetify components in order to force crv-vuetify-styling
        // still implement via scss files and migrate Alias and default to vue-3-components!
        VAlert: { class: 'crv-vuetify-styling' },
        VBtn: { class: 'crv-vuetify-styling'},
        VCard: { class: 'crv-vuetify-styling' },
        VCheckbox: { class: 'crv-vuetify-styling' },
        VChip: { class: 'crv-vuetify-styling' },
        VCol: { class: 'crv-vuetify-styling' },
        VContainer: { class: 'crv-vuetify-styling' },
        VDataTable: { class: 'crv-vuetify-styling' },
        VDatePicker: { class: 'crv-vuetify-styling' },
        VDialog: { class: 'crv-vuetify-styling' },
        VDivider: { class: 'crv-vuetify-styling' },
        // VIcon: { class: 'crv-vuetify-styling' },
        VListItem: { class: 'crv-vuetify-styling' },
        VMenu: { class: 'crv-vuetify-styling' },
        VOverlay: { class: 'crv-vuetify-styling' },
        VProgressCircular: { class: 'crv-vuetify-styling' },
        VRow: { class: 'crv-vuetify-styling' },
        VSelect: { class: 'crv-vuetify-styling' },
        VSlider: { class: 'crv-vuetify-styling' },
        VSpacer: { class: 'crv-vuetify-styling' },
        VSwitch: { class: 'crv-vuetify-styling' },
        VTab: { class: 'crv-vuetify-styling' },
        VTable: { class: 'crv-vuetify-styling' },
        VTabItem: { class: 'crv-vuetify-styling' },
        VTextField: { class: 'crv-vuetify-styling' },
        VTextarea: { class: 'crv-vuetify-styling' },
        VToolbar: { class: 'crv-vuetify-styling' },
        VTooltip: { class: 'crv-vuetify-styling' },
    }
}

export default aliasesAndDefaults